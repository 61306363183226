import React, {useState} from "react";
import "../styles/signup.css";

import { Amplify, API, graphqlOperation, Auth } from "aws-amplify";
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';
import axios from 'axios';


const awsconfig = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": process.env.REACT_APP_AWS_APPSYNC_GRAPHQLENDPOINT,
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": process.env.REACT_APP_AWS_APPSYNC_APIKEY,


    "aws_cognito_identity_pool_id": process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": process.env.REACT_APP_AWS_USER_POOLS_ID,
    "aws_user_pools_web_client_id": process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};

const invokeUrl = 
'https://nlg3ef4y53.execute-api.us-east-1.amazonaws.com/dev/items';

Amplify.configure(awsconfig);


const Signup = () => {
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });
    const [fulllocationis, setFulllocationIs] = useState('Unknown');
    const [originatedfromfinalis, setOriginatedfromfinalIs] = useState('Unknown');
    const [fulllocationextended, setFulllocationextended] = useState('Unknown');

    const [confirmationCode, setConfirmationCode] = useState('');
    const [isConfirmation, setIsConfirmation] = useState(false);
    const [isLogin, setIsLogin] = useState(false); // New state



    const figureOutLocation = async (passinaction) => {
        try {
          
          const response = await axios.get(`https://ipapi.co/json/`);
          const responsecode = response.status;
          let cityy;
          let countryy;
          let fulllocation = "Unknown. The call to ipapi.co failed.";
      
          if (responsecode !== 200) {
            const countryboo = "Unknown"
            const cityboo = "Unknown"
            const regionboo = "Unknown"
            
            cityy = cityboo;
            countryy = countryboo;
      
            fulllocation = cityy +  ", " + regionboo + ", " + countryy;
          } else {
            const countryboo = await response.data.country_name;
            const cityboo = await response.data.city;
            const regionboo = await response.data.region;
            cityy = cityboo;
            countryy = countryboo;
            fulllocation = cityy + ", " + regionboo + ", "+ countryy;
          }
      
          const timeyprefixed = new Intl.DateTimeFormat('en-US', { timeZone: 'America/New_York', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(new Date());
          // turn the / into - for the id and turn : into - and turn spaces into - for timey 
      
          let timey;
          if (timeyprefixed) {
            timey = timeyprefixed.replace(/\//g, "-").replace(/:/g, "-").replace(/ /g, "-").replace(/,/g, "");
            //console.log(timey + "timey is defined")
          } else {
            // Handle the case when timeyprefixed is undefined
            //console.error('timeyprefixed is undefined');
          }
      
      
          const concatedCityAndDate = cityy + "-" + countryy +  "-" + timey;
          // create 
          const idForVisitAndyF = concatedCityAndDate;
      
          // Store idForVisit in sessionStorage
          sessionStorage.setItem('idForVisitAndyF', idForVisitAndyF);
      
      
          setFulllocationIs(fulllocation);
      
          const params = new URLSearchParams(window.location.search);
          const originatedfrom = params.get('o');
          let originatedfromfinal;
      
       
           if (originatedfrom === "twb") {
             originatedfromfinal = "My twitter bio";
           } else if (originatedfrom === "ycfp") {
             originatedfromfinal = "yc founder profile page";
           } else if (originatedfrom === "yccs") {
             originatedfromfinal = "yc cofounder matching page";
           } else if (originatedfrom === "p") {
             originatedfromfinal = "Pitch";
           } else if (originatedfrom) {
             originatedfromfinal = originatedfrom;
           } else if (!originatedfrom) {
             originatedfromfinal = "other";
           }
      
           setOriginatedfromfinalIs(originatedfromfinal);
      
      
      
      const title = passinaction;
      
      // Constructing the email header and body
      const emailHeader = `${title}`;
      const emailBody = `
      Origin: ${originatedfromfinal}
      Location: ${fulllocation}
      `;
      
      const payload = {
          emailHeader: emailHeader,
          emailBody: emailBody
      };
      
      
       
      fetch(invokeUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      })
      .then((response) => response.json())
      .then((data) => {
       // console.log('Success:', data);
      })
      .catch((error) => {
        //console.error('Error:', error);
      });
    
        
      
      //random number between 1 and 100 
      const randomnumber = Math.floor(Math.random() * 100) + 1;
      fulllocationextended = fulllocation + "-" + timey.slice(0, 6).toString() + "-rand-" + randomnumber.toString();
      
      


      
      
      
      
      
      } catch (error) {
         // console.error(error);
        }
        }



    const handleInputChange = event => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async event => {
        event.preventDefault();
        if (!isConfirmation) {

            if (isLogin) {
                try {
                    await Auth.signIn(formData.email, formData.password);
                    console.log('User signed in successfully!');
                    window.location.href = '/dashboard';
                } catch (error) {
                    console.error("Login failed:", error);
                    alert("Incorrect email or password. Please try again.")
                }
            } else {

            try {
                await Auth.signUp({
                    username: formData.email,
                    password: formData.password,
                    attributes: {
                        email: formData.email,
                        name: formData.email
                    }
                });
                setIsConfirmation(true);
            } catch (error) {
                console.error("Signup failed:", error);
                alert("This email is already in use. Please log in instead or use a different email.")
            }
        }

        } else {
            try {
                await Auth.confirmSignUp(formData.email, confirmationCode);
                console.log('User signed up successfully!');
                await Auth.signIn(formData.email, formData.password);



                /*
                    type User @model {
                    id: ID!
                    email: String
                    location: String
                    origin: String
                    apikey: String @index(name: "ApikeyIndex", sortKeyFields: ["id"])
                    cumulativeowed: Float
                    cumulativepaid: Float
                    maxmonthly: Float
                    password: String
                    cumulativecharacters: Float
                    }

                */

                await figureOutLocation("New user signup");



                /*

                type User @model {
                id: ID!
                email: String
                location: String
                origin: String
                apikey: String @index(name: "ApikeyIndex", sortKeyFields: ["id"])
                cumulativeowed: Float
                cumulativepaid: Float
                cumulativefreeusagedollars: Float
                maxmonthly: Float
                password: String
                cumulativetokens: Float
                cumulativetokenspaid: Float
                cumulativefreeusagetokens: Float
                isusersubscribed: Boolean
                stripetoken: String
                createdAt: AWSDateTime
                updatedAt: AWSDateTime
                subscriptionid: String
                paymentsuccess: Boolean
                }

                */


                // make a random 10 char string
                const randomstring = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);


                // create user in database and link to cognito user and give them an api key called "free-trial-thenrandom10charstring"
                await API.graphql(graphqlOperation(mutations.createUser, {input: {
                    id: formData.email,
                    email: formData.email,
                    location: fulllocationis,
                    origin: originatedfromfinalis,
                    apikey: "none",
                    cumulativeowed: 0,
                    cumulativepaid: 0,
                    cumulativefreeusagedollars: 0,
                    cumulativetokens: 0,
                    cumulativetokenspaid: 0,
                    cumulativefreeusagetokens: 0,
                    maxmonthly: 100000,
                    isusersubscribed: false,
                    subscriptionid: "none",
                    paymentsuccess: false,
                }}));



                // send to dashboard page
                window.location.href = '/dashboard';
            } catch (error) {
                console.error("Confirmation failed:", error);
            }
        }
    };

    return (
        <div className="signup">
            <div className="signup-container">
                <div className="signup-left">
                    <h1>{isLogin ? "Log in" : "Sign up"}</h1>
                    <p>
                        {!isConfirmation 
                            ? "Automate your online research with a simple API call. Access a powerful AI web navigation agent seamlessly."
                            : "Check your email for the verification code"}
                    </p>
                </div>
                <div className="signup-right">
                    <form onSubmit={handleSubmit}>
                        {!isConfirmation && !isLogin ? (
                            <>
                                <input 
                                    type="email"
                                    name="email" 
                                    placeholder="Email"
                                    onChange={handleInputChange}
                                    value={formData.email}
                                />
                                <input 
                                    type="password"
                                    name="password" 
                                    placeholder="Password"
                                    onChange={handleInputChange}
                                    value={formData.password}
                                />
                                <button className="signupsub" type="submit">Sign up</button>
                            </>
                        ) : isLogin ? (
                            <>
                                <input 
                                    type="email"
                                    name="email" 
                                    placeholder="Email"
                                    onChange={handleInputChange}
                                    value={formData.email}
                                />
                                <input 
                                    type="password"
                                    name="password" 
                                    placeholder="Password"
                                    onChange={handleInputChange}
                                    value={formData.password}
                                />
                                <button className="signupsub" type="submit">Log in</button>
                            </>
                        ) : (
                            <>
                                <input 
                                    type="text" 
                                    placeholder="Confirmation Code"
                                    value={confirmationCode}
                                    onChange={(e) => setConfirmationCode(e.target.value)}
                                />
                                <button type="submit">Confirm</button>
                            </>
                        )}
                        {/* Toggle button */}
                        <button className="login-instead" type="button" onClick={() => setIsLogin(!isLogin)}>
                            {isLogin ? "Sign up instead" : "Log in instead"}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};
export default Signup;