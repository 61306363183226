/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      email
      location
      origin
      apikey
      cumulativeowed
      cumulativepaid
      cumulativefreeusagedollars
      maxmonthly
      password
      cumulativetokens
      cumulativetokenspaid
      cumulativefreeusagetokens
      isusersubscribed
      stripetoken
      createdAt
      updatedAt
      subscriptionid
      paymentsuccess
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      email
      location
      origin
      apikey
      cumulativeowed
      cumulativepaid
      cumulativefreeusagedollars
      maxmonthly
      password
      cumulativetokens
      cumulativetokenspaid
      cumulativefreeusagetokens
      isusersubscribed
      stripetoken
      createdAt
      updatedAt
      subscriptionid
      paymentsuccess
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      email
      location
      origin
      apikey
      cumulativeowed
      cumulativepaid
      cumulativefreeusagedollars
      maxmonthly
      password
      cumulativetokens
      cumulativetokenspaid
      cumulativefreeusagetokens
      isusersubscribed
      stripetoken
      createdAt
      updatedAt
      subscriptionid
      paymentsuccess
    }
  }
`;
export const createVisitor = /* GraphQL */ `
  mutation CreateVisitor(
    $input: CreateVisitorInput!
    $condition: ModelVisitorConditionInput
  ) {
    createVisitor(input: $input, condition: $condition) {
      id
      action
      location
      origin
      createdAt
      updatedAt
    }
  }
`;
export const updateVisitor = /* GraphQL */ `
  mutation UpdateVisitor(
    $input: UpdateVisitorInput!
    $condition: ModelVisitorConditionInput
  ) {
    updateVisitor(input: $input, condition: $condition) {
      id
      action
      location
      origin
      createdAt
      updatedAt
    }
  }
`;
export const deleteVisitor = /* GraphQL */ `
  mutation DeleteVisitor(
    $input: DeleteVisitorInput!
    $condition: ModelVisitorConditionInput
  ) {
    deleteVisitor(input: $input, condition: $condition) {
      id
      action
      location
      origin
      createdAt
      updatedAt
    }
  }
`;
