import React from 'react';
import "../styles/pricing.css";

function Pricing() {
    return (
        <div className='pricingcomp'>
            <h1 className='d-title'>Pricing</h1>
            <p className='line-5'>Only pay for what you use.</p>
            <p className='line-5' >$0.076 per 1000 tokens that you use.</p>
            <p className='line-5'>1 token is generally equal to 4 characters of text.</p>
            <p className='line-5'>Monitor costs and token usage in your Dashboard.</p>
            <p className='line-5'>Cancel your subscription anytime in the Dashboard.</p>


        </div>
    );
}

export default Pricing;
