import React, { useState, useEffect, useRef } from 'react';
import "../styles/dashboard.css";

import { Amplify, API, graphqlOperation, Auth } from "aws-amplify";
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';
import axios from 'axios';

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Payment from "./payment";



const awsconfig = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": process.env.REACT_APP_AWS_APPSYNC_GRAPHQLENDPOINT,
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": process.env.REACT_APP_AWS_APPSYNC_APIKEY,


    "aws_cognito_identity_pool_id": process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": process.env.REACT_APP_AWS_USER_POOLS_ID,
    "aws_user_pools_web_client_id": process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};

Amplify.configure(awsconfig);


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const invokeUrl = 
'https://nlg3ef4y53.execute-api.us-east-1.amazonaws.com/dev/items';

const pricePerToken = 0.000076;

const Dashboard = () => {
    const [tokensThisMonth, setTokensUsedThisMonth] = useState(0);
    const [isusersubscribed, setIsUserSubscribed] = useState(false);
    const [gettingsubscribednow, setGettingSubscribedNow] = useState(false);
    const [userEmailIsThis, setUserEmailIsThis] = useState(null);
    const [showAPIkey, setShowAPIkey] = useState(false);
    const [fulllocationis, setFulllocationIs] = useState("none");
    const [originatedfromfinalis, setOriginatedfromfinalIs] = useState("none");
    const [fulllocationextended, setFulllocationextended] = useState("none");

    const isCalled = useRef(false);

    useEffect(() => {
        if (isCalled.current) {
            return;
        } 
        const getApiUsage = async () => {

            try {
            const user = await Auth.currentAuthenticatedUser();
            const email = user.attributes.email;

            setUserEmailIsThis(email);

            // set the apiusage

            const getUserDetails = await API.graphql(graphqlOperation(queries.getUser, { id: email }));

            setIsUserSubscribed(getUserDetails.data.getUser.isusersubscribed);




            // check first if user is subscribed

            try {
                if (getUserDetails.data.getUser.isusersubscribed) {
                                // subscriptionId is an item from the object user getUserDetails
    
                                const customerId = getUserDetails.data.getUser.stripetoken;
    
                                const response = await axios.get(`https://webslingerv1.ngrok.app/api/usage/${customerId}`);
                                setTokensUsedThisMonth(response.data);
    
                                
                }
    
                
            } catch (error) {
                alert("Our API is undergoing maintenance. Please try again later.")
                window.location.href = '/';
                
            }



            } catch (error) {
                console.error("Error fetching user email:", error);
               alert('You are not logged in');
                 //send to /login

                window.location.href = '/signup';

                return null;
                
            }






        }
        getApiUsage();
        console.log("Get whether subbed or not")
        isCalled.current = true;
    }, []);

    



    const figureOutLocation = async (passinaction) => {
        try {
          
          const response = await axios.get(`https://ipapi.co/json/`);
          const responsecode = response.status;
          let cityy;
          let countryy;
          let fulllocation = "Unknown. The call to ipapi.co failed.";
      
          if (responsecode !== 200) {
            const countryboo = "Unknown"
            const cityboo = "Unknown"
            const regionboo = "Unknown"
            
            cityy = cityboo;
            countryy = countryboo;
      
            fulllocation = cityy +  ", " + regionboo + ", " + countryy;
          } else {
            const countryboo = await response.data.country_name;
            const cityboo = await response.data.city;
            const regionboo = await response.data.region;
            cityy = cityboo;
            countryy = countryboo;
            fulllocation = cityy + ", " + regionboo + ", "+ countryy;
          }
      
          const timeyprefixed = new Intl.DateTimeFormat('en-US', { timeZone: 'America/New_York', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(new Date());
          // turn the / into - for the id and turn : into - and turn spaces into - for timey 
      
          let timey;
          if (timeyprefixed) {
            timey = timeyprefixed.replace(/\//g, "-").replace(/:/g, "-").replace(/ /g, "-").replace(/,/g, "");
            //console.log(timey + "timey is defined")
          } else {
            // Handle the case when timeyprefixed is undefined
            //console.error('timeyprefixed is undefined');
          }
      
      
          const concatedCityAndDate = cityy + "-" + countryy +  "-" + timey;
          // create 
          const idForVisitAndyF = concatedCityAndDate;
      
          // Store idForVisit in sessionStorage
          sessionStorage.setItem('idForVisitAndyF', idForVisitAndyF);
      
      
          setFulllocationIs(fulllocation);
      
          const params = new URLSearchParams(window.location.search);
          const originatedfrom = params.get('o');
          let originatedfromfinal;
      
       
           if (originatedfrom === "twb") {
             originatedfromfinal = "My twitter bio";
           } else if (originatedfrom === "ycfp") {
             originatedfromfinal = "yc founder profile page";
           } else if (originatedfrom === "yccs") {
             originatedfromfinal = "yc cofounder matching page";
           } else if (originatedfrom === "p") {
             originatedfromfinal = "Pitch";
           } else if (originatedfrom) {
             originatedfromfinal = originatedfrom;
           } else if (!originatedfrom) {
             originatedfromfinal = "other";
           }
      
           setOriginatedfromfinalIs(originatedfromfinal);
      
      
      
      const title = passinaction;
      
      // Constructing the email header and body
      const emailHeader = `${title}`;
      const emailBody = `
      Origin: ${originatedfromfinal}
      Location: ${fulllocation}
      `;
      
      const payload = {
          emailHeader: emailHeader,
          emailBody: emailBody
      };
      
      
       
      fetch(invokeUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      })
      .then((response) => response.json())
      .then((data) => {
       // console.log('Success:', data);
      })
      .catch((error) => {
        //console.error('Error:', error);
      });
    
        
      
      //random number between 1 and 100 
      const randomnumber = Math.floor(Math.random() * 100) + 1;
      fulllocationextended = fulllocation + "-" + timey.slice(0, 6).toString() + "-rand-" + randomnumber.toString();
      
      


      
      
      
      
      
      } catch (error) {
         // console.error(error);
        }
    }










    const handleCancelSubscription = async () => {
        // cancel sub
        setIsUserSubscribed(false);

        // update user in db
        await Auth.currentAuthenticatedUser()
        // get the user's email
        .then(async (user) => {
            const email = user.attributes.email;
            // check if user is subscribed
            const userSubscription = await API.graphql(graphqlOperation(queries.getUser, { id: email }));
            const isUserSubscribe = userSubscription.data.getUser.isusersubscribed;
            // if true then alert user that they are already subscribed
            if (isUserSubscribe) {
                await API.graphql(graphqlOperation(mutations.updateUser, { input: { id: email, isusersubscribed: false, apikey: "none" } }));
                console.log('user unsubscribed successfully');
                // delete subscription in stripe here
                const response = await axios.post('https://webslingerv1.ngrok.app/api/cancel-subscription', {}, {
                    headers: {
                        'user-email': email
                    }
                });
                
                if (response.data.message === 'Subscription canceled successfully!') {
                    console.log('subscription deleted in stripe');
                    alert('You have successfully canceled your subscription. You will not be charged again.');

                    await figureOutLocation(email + " canceled subscription")
                    // send to /dashboard
                    window.location.href = '/dashboard';
                } else {
                    console.error("Error deleting subscription in stripe:", response.data.error);
                    alert("There was an error in canceling your subscription. Please try again later or email support at andy@webslingerapi.com")
                }
                
            } else {
                alert('You are not subscribed');
                return;
            }
        })
    }



    const gettingsubscribednowfunction = async () => {
        
        await Auth.currentAuthenticatedUser()
        // get the user's email
        .then(async (user) => {
            const email = user.attributes.email;
            // check if user is subscribed
            const userSubscription = await API.graphql(graphqlOperation(queries.getUser, { id: email }));
            const isUserSubscribe = userSubscription.data.getUser.isusersubscribed;
            // if true then alert user that they are already subscribed
            if (isUserSubscribe) {
                alert('You are already subscribed');
                return;
            } else if (!isUserSubscribe) {
                setGettingSubscribedNow(true);
                console.log('user is not subscribed, opening payment modal')
                await figureOutLocation(email + " trying to get subscribed now")

                setUserEmailIsThis(email);
            }})
 
    }


    const subscribeFn = async () => {
        // check if user is subscribed

        const user = await Auth.currentAuthenticatedUser()
        // get the user's email

        const email = user.attributes.email;

        //change ther isusersubscribed to true, and change their api key to a new one (generate a new one) 

        const updatedUser = {
            id: email,
            isusersubscribed: true,
            apikey: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)

        }


        await API.graphql(graphqlOperation(mutations.updateUser, { input: updatedUser }));


        console.log('user subscribed successfully')
        alert('You are now subscribed!')
        setIsUserSubscribed(true);
        setGettingSubscribedNow(false);

        await figureOutLocation(email + " subscribed successfully!")



    }


    const handleSuccessfulPayment = async (paymentMethod) => {
        // Handle post-payment logic here
        console.log("[PaymentMethod]", paymentMethod);
        await subscribeFn();
    }

    const getAndShowAPIkey = async () => {

        const user = await Auth.currentAuthenticatedUser()
        // get the user's email
        const email = user.attributes.email;

        // get the api key
        const queryUserInDB = await API.graphql(graphqlOperation(queries.getUser, { id: email }));
        const apikeyisthis = queryUserInDB.data.getUser.apikey;


    setShowAPIkey(apikeyisthis)
    }


    const changeAPIkey = async () => {
            
            const user = await Auth.currentAuthenticatedUser()
            // get the user's email
            const email = user.attributes.email;
    
            // change the api key
            const updatedUser = {
                id: email,
                apikey: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
    
            }

            await API.graphql(graphqlOperation(mutations.updateUser, { input: updatedUser }));
            console.log('user api key changed successfully')
            alert('Your API key has been changed!')
            setShowAPIkey(false);
        }
    

    return (
        <div  className="dashboard">


        {!gettingsubscribednow && (
        <div >
            <h1 className='d-title'>API Usage</h1>




            <p className='line-5'>You have analyzed {tokensThisMonth} tokens (or roughly {tokensThisMonth*4} characters of text) this month.</p>
            <p className='line-5'>Your bill is ${(tokensThisMonth*pricePerToken).toFixed(2)} for this month.</p>



            {isusersubscribed && (

                <div>
                                {!showAPIkey && (
            <div>
                
                <button className='getapikey' onClick={() => getAndShowAPIkey()}>Show API key</button>

            </div>
            )}

            {showAPIkey && (
            <div>
                
                <p>Your API key is: {showAPIkey}</p>
                <button className='changeapikey' onClick={() => changeAPIkey()}>Change API key</button>

            </div>
            )}

            <br/>
            <button className='cancelsub' onClick={handleCancelSubscription}>Cancel Subscription</button>


            </div>
            )}
            {!isusersubscribed && (
                <button className='subscribe' onClick={() => gettingsubscribednowfunction()}>Get your API key</button>  
            )}

            {/* PUT THE STRIPE ADD CUSTOMER CODE HERE. IT MUST SAVE THEIR CREDIT CARD INFORMATION */}

        </div>
        )}

                    
        {gettingsubscribednow && (
            <div>
            <Elements stripe={stripePromise}>
                <Payment email={userEmailIsThis} onSuccessfulPayment={handleSuccessfulPayment} />
            </Elements>




            <button className='back' onClick={() => setGettingSubscribedNow(false)}>Back</button>
            </div>
        )}
        
        </div>

    );
};

export default Dashboard;
