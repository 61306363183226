import React, {useEffect, useState, useRef} from 'react';
import '../styles/navbar.css'; 
import logois from '../images/70.png'

import { Amplify, API, graphqlOperation, Auth } from "aws-amplify";


const awsconfig = {
  "aws_project_region": "us-east-1",
  "aws_appsync_graphqlEndpoint": process.env.REACT_APP_AWS_APPSYNC_GRAPHQLENDPOINT,
  "aws_appsync_region": "us-east-1",
  "aws_appsync_authenticationType": "API_KEY",
  "aws_appsync_apiKey": process.env.REACT_APP_AWS_APPSYNC_APIKEY,


  "aws_cognito_identity_pool_id": process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  "aws_cognito_region": "us-east-1",
  "aws_user_pools_id": process.env.REACT_APP_AWS_USER_POOLS_ID,
  "aws_user_pools_web_client_id": process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  "oauth": {},
  "aws_cognito_username_attributes": [
      "EMAIL"
  ],
  "aws_cognito_social_providers": [],
  "aws_cognito_signup_attributes": [
      "EMAIL"
  ],
  "aws_cognito_mfa_configuration": "OFF",
  "aws_cognito_mfa_types": [
      "SMS"
  ],
  "aws_cognito_password_protection_settings": {
      "passwordPolicyMinLength": 8,
      "passwordPolicyCharacters": []
  },
  "aws_cognito_verification_mechanisms": [
      "EMAIL"
  ]
};

Amplify.configure(awsconfig);



const Navbar = () => {
  const [isUserSignedIn, setIsUserSignedIn] = useState(false);

  const hasRun = useRef(false);

  console.log('Component rendered');


  useEffect(() => { 
    if (hasRun.current) {
      return;
    }
    checkIfUserIsInUserPool();
    hasRun.current = true;
  }, []);


  const checkIfUserIsInUserPool = async () => {
    try {
      console.log("Checking for user...");

      const user = await Auth.currentAuthenticatedUser();
      console.log("User exists");
      setIsUserSignedIn(true);
    } catch (error) {
      console.log("No user.");
      setIsUserSignedIn(false);
    }
  };
  
  

  const sendToHome = async () => {
    window.location.href = '/';
  }



  return (
    <div className="navbar">
      <img src={logois} alt="Your Logo" className="logo" onClick={sendToHome} />
      <div className="nav-links">
        <a href="/pricing">Pricing</a>
        <a href="/docs">Developers</a>
        {isUserSignedIn && <a href="/dashboard">Dashboard</a>}
        {!isUserSignedIn && <a href="/signup">Start now</a>}
      </div>
    </div>
  );
}

export default Navbar;
