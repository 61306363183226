import React, { useState, useEffect, useRef} from 'react';
import axios from 'axios';

import { Amplify, API, graphqlOperation, Auth } from "aws-amplify";
import * as mutations from '../graphql/mutations';
import * as queries from "../graphql/queries";
import 'font-awesome/css/font-awesome.min.css';
import '../styles/landing_page.css';


const awsconfig = {
  "aws_project_region": "us-east-1",
  "aws_appsync_graphqlEndpoint": process.env.REACT_APP_AWS_APPSYNC_GRAPHQLENDPOINT,
  "aws_appsync_region": "us-east-1",
  "aws_appsync_authenticationType": "API_KEY",
  "aws_appsync_apiKey": process.env.REACT_APP_AWS_APPSYNC_APIKEY,


  "aws_cognito_identity_pool_id": process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  "aws_cognito_region": "us-east-1",
  "aws_user_pools_id": process.env.REACT_APP_AWS_USER_POOLS_ID,
  "aws_user_pools_web_client_id": process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  "oauth": {},
  "aws_cognito_username_attributes": [
      "EMAIL"
  ],
  "aws_cognito_social_providers": [],
  "aws_cognito_signup_attributes": [
      "EMAIL"
  ],
  "aws_cognito_mfa_configuration": "OFF",
  "aws_cognito_mfa_types": [
      "SMS"
  ],
  "aws_cognito_password_protection_settings": {
      "passwordPolicyMinLength": 8,
      "passwordPolicyCharacters": []
  },
  "aws_cognito_verification_mechanisms": [
      "EMAIL"
  ]
};

Amplify.configure(awsconfig);

/*
    await API.graphql({
    query: mutations.createUser,
    variables: {
      input: {
        email: email,
        location: fulllocation,
        origin: originatedfromfinal,
      }
    }
  });

*/

let fulllocationextended = "Unknown. Failed.";


function LandingPage() {
  const [isUserSignedIn, setIsUserSignedIn] = useState(false);
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const [isAPIinUse, setIsAPIinUse] = useState(false);
  const [formData, setFormData] = useState({
    maxResearchDepth: '',
    yourResearchObjective: '',
    url: ''
  });
  

  // set up location
  const [fulllocationis, setFulllocationIs] = useState("Use state for location did not update properly.");
  const [originatedfromfinalis, setOriginatedfromfinalIs] = useState("Use state for originatedfromfinal did not update properly.");

  const [logs, setLogs] = useState([]);

  const hasRun = useRef(false);

  const invokeUrl = 
  'https://nlg3ef4y53.execute-api.us-east-1.amazonaws.com/dev/items';

  useEffect(() => {
    if (hasRun.current) return;
      figureOutLocation("User viewed Webslinger API test page");
      // check if user exists in userpool auth aws

      Auth.currentAuthenticatedUser()
      .then(user => {
        console.log("User is signed in: ", user.attributes.email);

        setIsUserSignedIn(true);
      })
      .catch(err => {
        console.log("User is not signed in: ", err);
        setIsUserSignedIn(false);
      });

      




    hasRun.current = true;
  }, []);



  console.log("THE LANDING PAGE HAS BEEN RENDERED")




  

  useEffect(() => {
    const ws = new WebSocket('ws://localhost:4000');
    
    ws.onopen = () => {
        console.log('WebSocket connected');
    };

    ws.onmessage = (event) => {
        const logMessage = event.data;
        setLogs((prevLogs) => [...prevLogs, logMessage]);
    };

    ws.onclose = () => {
        console.log('WebSocket disconnected');
    };

    ws.onerror = (error) => {
        console.error('WebSocket Error:', error);
    };

    return () => {
        ws.close();
    };
}, []);


const Spinner = () => {
  return <div className='loading-message'>Loading... Please do not close. This can take up to 2 minutes.</div>;  // You can replace this with your own spinner or use a library
};


const figureOutLocation = async (passinaction) => {
  try {
    
    const response = await axios.get(`https://ipapi.co/json/`);
    const responsecode = response.status;
    let cityy;
    let countryy;
    let fulllocation = "Unknown. The call to ipapi.co failed.";

    if (responsecode !== 200) {
      const countryboo = "Unknown"
      const cityboo = "Unknown"
      const regionboo = "Unknown"
      
      cityy = cityboo;
      countryy = countryboo;

      fulllocation = cityy +  ", " + regionboo + ", " + countryy;
    } else {
      const countryboo = await response.data.country_name;
      const cityboo = await response.data.city;
      const regionboo = await response.data.region;
      cityy = cityboo;
      countryy = countryboo;
      fulllocation = cityy + ", " + regionboo + ", "+ countryy;
    }

    const timeyprefixed = new Intl.DateTimeFormat('en-US', { timeZone: 'America/New_York', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(new Date());
    // turn the / into - for the id and turn : into - and turn spaces into - for timey 

    let timey;
    if (timeyprefixed) {
      timey = timeyprefixed.replace(/\//g, "-").replace(/:/g, "-").replace(/ /g, "-").replace(/,/g, "");
      //console.log(timey + "timey is defined")
    } else {
      // Handle the case when timeyprefixed is undefined
      //console.error('timeyprefixed is undefined');
    }


    const concatedCityAndDate = cityy + "-" + countryy +  "-" + timey;
    // create 
    const idForVisitAndyF = concatedCityAndDate;

    // Store idForVisit in sessionStorage
    sessionStorage.setItem('idForVisitAndyF', idForVisitAndyF);


    setFulllocationIs(fulllocation);

    const params = new URLSearchParams(window.location.search);
    const originatedfrom = params.get('o');
    let originatedfromfinal;

 
     if (originatedfrom === "twb") {
       originatedfromfinal = "My twitter bio";
     } else if (originatedfrom === "ycfp") {
       originatedfromfinal = "yc founder profile page";
     } else if (originatedfrom === "yccs") {
       originatedfromfinal = "yc cofounder matching page";
     } else if (originatedfrom === "p") {
       originatedfromfinal = "Pitch";
     } else if (originatedfrom) {
       originatedfromfinal = originatedfrom;
     } else if (!originatedfrom) {
       originatedfromfinal = "other";
     }

     setOriginatedfromfinalIs(originatedfromfinal);



const title = passinaction;

// Constructing the email header and body
const emailHeader = `${title}`;
const emailBody = `
Origin: ${originatedfromfinal}
Location: ${fulllocation}
`;

const payload = {
    emailHeader: emailHeader,
    emailBody: emailBody
};



fetch(invokeUrl, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(payload),
})
.then((response) => response.json())
.then((data) => {
 // console.log('Success:', data);
})
.catch((error) => {
  //console.error('Error:', error);
});
 
  

//random number between 1 and 100 
const randomnumber = Math.floor(Math.random() * 100) + 1;
fulllocationextended = fulllocation + "-" + timey.slice(0, 6).toString() + "-rand-" + randomnumber.toString();


///// create Visitor in the database
    await API.graphql({
      query: mutations.createVisitor,
      variables: {
        input: {
          id: fulllocationextended,
          action: "Viewed Webslinger home page.",
          location: fulllocation,
          origin: originatedfromfinal,
        }
      }
    });

    //console.log("Visitor created in database with id: ", idForVisitAndyF);


/*
    API.graphql({
      query: mutations.updateVisitor,
      variables: {
        input: {
          id: fulllocationextended,
          action: "Used Webslinger API.",
          location: fulllocationis,
          origin: originatedfromfinal,
        }
      }
    });

*/





} catch (error) {
   // console.error(error);
  }
  }


  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (formData.maxResearchDepth === '') {
      formData.maxResearchDepth = 2;
    }

    if (formData.yourResearchObjective === '') {
      alert("Please enter a research objective.");
      return;
    }

    if (formData.url === '') {
      alert("Please enter a URL.");
      return;
    }

    // if url is not a valid url then alert the user
    const url = formData.url;

    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      alert("Please enter a valid URL.");
      return;
    }
    

    console.log("Setting API in use to: ", true);
    setIsAPIinUse(true);
    
    axios.post('https://webslingerv1.ngrok.app/webslinger', formData, {
      timeout: 150000,  // 150 seconds timeout
      headers: {
        'x-api-key': 'free-trial-bigdog-noodle'
      }
    }).then(response => {
      setResult(response.data);
      console.log("Setting API in use to: ", false);
      setIsAPIinUse(false);



    const params = new URLSearchParams(window.location.search);
    const originatedfrom = params.get('o');
    let originatedfromfinal;
 
     if (originatedfrom === "twb") {
       originatedfromfinal = "My twitter bio";
     } else if (originatedfrom === "ycfp") {
       originatedfromfinal = "yc founder profile page";
     } else if (originatedfrom === "yccs") {
       originatedfromfinal = "yc cofounder matching page";
     } else if (originatedfrom === "p") {
       originatedfromfinal = "Pitch";
     } else if (originatedfrom) {
       originatedfromfinal = originatedfrom;
     } else if (!originatedfrom) {
       originatedfromfinal = "other";
     }
     setOriginatedfromfinalIs(originatedfromfinal);
     const queryIs = formData.yourResearchObjective;
     const urlIs = formData.url;
     const title = "User used Webslinger API.";
     
     // Constructing the email header and body
     const emailHeader = `${title}`;
     const emailBody = `
     Query: ${queryIs}
     Starting URL: ${urlIs}
     Origin: ${originatedfromfinal}
     Location: ${fulllocationis}
     Response: ${JSON.stringify(response.data)}
     `;
     
     const payload = {
         emailHeader: emailHeader,
         emailBody: emailBody
     };


  
     fetch(invokeUrl, {
       method: 'POST',
       headers: {
         'Content-Type': 'application/json',
       },
       body: JSON.stringify(payload),
     })
     .then((response) => response.json())
     .then((data) => {
      // console.log('Success:', data);
     })
     .catch((error) => {
      // console.error('Error:', error);
     }); 

  



    // update Visitor in the database with action: "Used Webslinger API"
    API.graphql({
      query: mutations.updateVisitor,
      variables: {
        input: {
          id: fulllocationextended,
          action: "Used Webslinger API.",
          location: fulllocationis,
          origin: originatedfromfinal,
        }
      }
    });
    })
    .catch(err => {
      setError(err.message);

      // update Visitor in the database with action: "Failed to use Webslinger API"
      API.graphql({
        query: mutations.updateVisitor,
        variables: {
          input: {
            id: fulllocationextended,
            action: "Failed to use Webslinger API.",
            location: fulllocationis,
            origin: originatedfromfinalis,
          }
        }
      });

      console.log("Setting API in use to: ", false);
      setIsAPIinUse(false);


      // get the input value
      const emailaddy = prompt('The Webslinger API has reached its limit. Enter your email address to be notified when this API is back online.');

      // if the user entered an email address
      if (emailaddy) {
        // send the email address to the backend

        

    const params = new URLSearchParams(window.location.search);
    const originatedfrom = params.get('o');
    let originatedfromfinal;
 
     if (originatedfrom === "twb") {
       originatedfromfinal = "My twitter bio";
     } else if (originatedfrom === "ycfp") {
       originatedfromfinal = "yc founder profile page";
     } else if (originatedfrom === "yccs") {
       originatedfromfinal = "yc cofounder matching page";
     } else if (originatedfrom === "p") {
       originatedfromfinal = "Pitch";
     } else if (originatedfrom) {
       originatedfromfinal = originatedfrom;
     } else if (!originatedfrom) {
       originatedfromfinal = "other";
     }

     setOriginatedfromfinalIs(originatedfromfinal);

     const aimsg = "Error: " + err.message;
     const title = "User requested use of Webslinger API: " + emailaddy;
     
     // Constructing the email header and body
     const emailHeader = `${title}`;
     const emailBody = `
     Error Message: ${aimsg}
     Origin: ${originatedfromfinal}
     Location: ${fulllocationis}

     `;
     
     const payload = {
         emailHeader: emailHeader,
         emailBody: emailBody
     };
     
 
      fetch(invokeUrl, {
       method: 'POST',
       headers: {
         'Content-Type': 'application/json',
       },
       body: JSON.stringify(payload),
     })
     .then((response) => response.json())
     .then((data) => {
      // console.log('Success:', data);
     })
     .catch((error) => {
      // console.error('Error:', error);
     });

 

      
    
    
    
    ///// put function to add user to waitlist here

    // query by id to see if user exists
    const checkifuserexists = API.graphql(graphqlOperation(queries.getUser, { id: emailaddy }))

    // if user exists, alert them that they are already on the waitlist
    if (checkifuserexists.data.getUser) {
      alert("You are already on the waitlist. You will only be emailed when we are ready to give you Webslinger API access.");
      return;
    } else {

    // create user in the waitlist
    API.graphql({
      query: mutations.createUser,
      variables: {
        input: {
          id: emailaddy,
          email: emailaddy,
          location: fulllocationis,
          origin: originatedfromfinal,
          cumulativeowed: 0,
          cumulativepaid: 0,
          maxmonthly: 100000,
          apikey: "none",


        }
      }
    });

    alert("You have been added to the waitlist. You will only be emailed when we are ready to give you Webslinger API access.");

    } 

    ///// put function to add user to waitlist here
    
    } else {
        


      


    const params = new URLSearchParams(window.location.search);
    const originatedfrom = params.get('o');
    let originatedfromfinal;
 
     if (originatedfrom === "twb") {
       originatedfromfinal = "My twitter bio";
     } else if (originatedfrom === "ycfp") {
       originatedfromfinal = "yc founder profile page";
     } else if (originatedfrom === "yccs") {
       originatedfromfinal = "yc cofounder matching page";
     } else if (originatedfrom === "p") {
       originatedfromfinal = "Pitch";
     } else if (originatedfrom) {
       originatedfromfinal = originatedfrom;
     } else if (!originatedfrom) {
       originatedfromfinal = "other";
     }

      setOriginatedfromfinalIs(originatedfromfinal);
     const aimsg = "Error: " + err.message;
     const title = "User failed to use Webslinger API.";
     
     // Constructing the email header and body
     const emailHeader = `${title}`;
     const emailBody = `
     Error Message: ${aimsg}
     Location: ${fulllocationis}
     Origin: ${originatedfromfinal}
     `;
     
     const payload = {
         emailHeader: emailHeader,
         emailBody: emailBody
     };



    fetch(invokeUrl, {
       method: 'POST',
       headers: {
         'Content-Type': 'application/json',
       },
       body: JSON.stringify(payload),
     })
     .then((response) => response.json())
     .then((data) => {
      // console.log('Success:', data);
     })
     .catch((error) => {
       //console.error('Error:', error);
     });
 


 

    }});







    /*

{"pages": 3,"characters": 9000, "cost": 0.13464749999999998,"data": [
{"page_number": 0,"innertextis": "","airesponse": "","accumulatedknowledge": ""},
{"page_number": 2,"innertextis": "Open menu
Open chat
Create post
Open inbox
1
Expand user menu
SEARCH RESULTS
Posts
Comments
back...
r/wallstreetbets
·
16 days ago","airesponse": "Is Unity going to Zero","accumulatedknowledge": ""},
{"page_number": 3,"innertextis": "Press J to jump to the feed. Press question mark to learn the rest of the keyboard shortcuts
Jump to content
Search within r/wallstreetbets
r/wallstreetbets
...
Unity was started in 2005 to democratize game making. To allow people who want to make games the ability to use a good engine. It has been built over the last 18 years into a juggernaut of where to....

Growing over the years with the c","airesponse": "https://blog","accumulatedknowledge": "Unity has recently altered its business model and now charges up to $0.15 per developer game install, causing an uproar among small studios and individual developers. "}
]}





    */

  };


  const logout = async () => {
    try {
      await Auth.signOut();
      console.log("User signed out successfully");
      setIsUserSignedIn(false);
      // refresh the page
      window.location.reload(false);
      alert("You have been signed out.")
    } catch (error) {
      console.log("Error signing out user: ", error);
    }
  };




  return (
    <div className="LandingPage">

      <div className='main-container'>

  
    <div className='intro-container'>
      <h2 className='title-1'>Webslinger AI Beta</h2>
      <p className='line-1'>Webslinger is a simple API that gives your app AI web navigation superpowers.</p>

{/*       <p className='line-2'>Only pay for what you use. No more monthly minimum costs.</p>
 */}      
      <p className='line-2'>Just add one line of code...</p>

      <div className='example-code-container'>
    <div className='example-input'>
        <p className='example-input-code-for-api'>
            <span className="keyword">const</span> <span className="variable">response</span> = <span className="keyword">await</span> <span className="object">axios</span>.<span className="method">post</span>(<span className="string">'https://webslingerv1.ngrok.app/webslinger'</span>,
            {'{'}
            <br/>
            <span className="indent-one"><span className="property">headers</span>: {'{'}</span>
            <span className="indent-two"><span className="property">'x-api-key'</span>: <span className="string">'YOUR_API_KEY'</span></span>
            <span className="indent-one">{'}'},</span>
            <br/>
            <span className="indent-one"><span className="property">data</span>: {'{'}</span>
            <br/>
            <span className="indent-two"><span className="property">maxResearchDepth</span>: max_number_of_urls_to_visit,</span>
            <br/>
            <span className="indent-two"><span className="property">yourResearchObjective</span>: <span className="string">'What stock will double soon.'</span>,</span>
            <br/>
            <span className="indent-two"><span className="property">url</span>: url_to_start_on,</span>
            <br/>
            <span className="indent-one">{'}'}</span>
            {'}'});
        </p>
    </div>
</div>

      <div className='spacing-here'>

      <p className='line-4'>... and after our AI is done researching you will receive a JSON object response containing everything it saw, every URL it went to, the exact cost of this call, and the key insights it gathered about your research objective.</p>
      
      <p className='line-5'>See if its the right fit for your application - test it quickly below:</p>
      </div>


    </div>

    <div className='form-container'>
    <form onSubmit={handleSubmit} className="custom-form">
    <div className="form-group">
    <label htmlFor="researchObj">Research Objective</label>
        <input 
            className="large-input"
            name="yourResearchObjective" 
            value={formData.yourResearchObjective} 
            onChange={handleChange} 
            placeholder="Find me an actionable market insight" 
            required
        />
    </div>
    <div className="form-group">
    <label htmlFor="url">URL</label>
      <input 
          className="large-input"
          name="url" 
          value={formData.url} 
          onChange={handleChange} 
          placeholder="https://reddit.com/r/wallstreetbets/" 
          required
      />
    </div>
    <div className="form-group">
        <label htmlFor="maxResearchDepth">Max Research Depth:</label>
        <select 
            name="maxResearchDepth" 
            value={formData.maxResearchDepth} 
            onChange={handleChange} 
            required
            className="form-select"
        >
            <option value="">Max Research Depth</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
        </select>
    </div>

    <div>
      {isAPIinUse ? (
        <Spinner />
      ) : (
        <div className="form-group">
          <button type="submit" onClick={handleSubmit}>Submit</button>
        </div>
      )}
    </div>
    </form>

    </div>




{/* THE API LOGS IF NEEDED
<div className='the-api-logs-container'>
<h2>API logs below:</h2>

{logs.map((log, index) => (
          <p key={index}>{log}</p>
      ))}
</div>
 */}

<br/>
<br />

<div className='result-ouput'>


{result && (

<div className='the-json-output-container'>
    <h2>Final JSON output below:</h2>
    {result && 
        <div className="larger-font">
            <pre>
                <span className="json-punctuation">{`{`}</span>
                <span className="json-key">"pages"</span>: <span className="json-number">{result.pages}</span>,
                <span className="json-key">"characters"</span>: <span className="json-number">{result.characters}</span>,
                <span className='json-key'> "cost"</span>: <span className="json-number">{result.cost}</span>,
                <span className="json-key">"data"</span>: <span className="json-punctuation">{`[`}</span>
                {result.data && result.data.slice(1).map((item, index, slicedArray) => (
                    <div key={index}>
                        <span className="json-punctuation">{`{`}</span>
                        <span className="json-key">"page_number"</span>: <span className="json-number">{item.page_number}</span>,
                        <span className="json-key">"airesponse"</span>: <span className="json-string">"{item.airesponse}"</span>,
                        <span className="json-key">"accumulatedknowledge"</span>: <span className="json-string">"{item.accumulatedknowledge}"</span>,
                        <span className="json-key">"innertextis"</span>: <span className="json-string">"{item.innertextis}"</span>,
                        <span className="json-punctuation">{`}`}</span>
                        {index !== slicedArray.length - 1 && <span className="json-punctuation">{`,`}</span>}
                    </div>
                ))}
                <span className="json-punctuation">{`]`}</span>
                <span className="json-punctuation">{`}`}</span>
            </pre>
        </div>
    }
    {error && <p className="larger-font">Error: {error}</p>}
</div>
)}

{result && (
  <div className='quick-explainer-prior-to-json'>
    <p className='line-x'>What each variable means:</p>
    <p className='line-5'><span className='var'>airesponse: </span> the AI's response to the text it read. It will spit back buttons, and text here that it wants to click on to navigate further, only if it finds a compelling lead to pursue.</p>
    <p className='line-5'><span className='var'>accumulatedknowledge:</span> the AI's key insights from these URLs about your research. If there isn't anything relevant, the AI will leave this field empty.</p>
    <p className='line-5'><span className='var'>innertextis:</span> the text the AI read on that webpage</p>
    <p className='line-5'><span className='var'>max research depth:</span> the maximum number of websites you want the AI to research.</p>
    <p className='line-5'><span className='var'>pages:</span> the number of websites the AI visited</p>
    <p className='line-5'><span className='var'>characters:</span> the number of characters the AI read</p>
    <p className='line-5'><span className='var'>cost:</span> the cost of this API call</p>
    <p className='line-5'><span className='var'>data:</span> an array of objects containing the page number, the text the AI read, the AI's response, and the AI's accumulated knowledge</p>
    <p className='line-5'><span className='var'>page_number:</span> tracks the number of URLs the AI visited</p>
    <p className='line-5'><span className='var'>model:</span> the AI model you want generating the key insights, and deciding how and when to navigate further.</p>

  </div>
)}
  
</div>


<br/>
<div className='footer-container'>
  <p className='footer-text'>Questions? <a href='mailto:andy@webslingerapi.com'>Email us</a></p>

  {isUserSignedIn && (<button className='logoutbutton' onClick={logout}>Log out</button>
  )}

  </div>



  </div>

    </div>
  );
}

export default LandingPage;