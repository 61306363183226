// Payment.js
import React, {useState} from "react";
import axios from 'axios';
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import "../styles/payment.css";

const Payment = ({ email, onSuccessfulPayment }) => {  // <-- Added 'email' prop
    const [loading, setLoading] = useState(false);
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
        event.preventDefault();



        


        setLoading(true);

        



        const cardElement = elements.getElement(CardElement);
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if (error) {
            console.log(error);
            alert("Error. Please make sure you're entering a valid card.");
            setLoading(false);
            return;
        }

        // Create customer on your backend
        const response = await axios.post('https://webslingerv1.ngrok.app/api/create-customer', { 
            paymentMethodId: paymentMethod.id,
            email: email   // <-- Included the email in the request
        });

        if (response.data.success) {
            // Store customer information (like customer ID) in your frontend state or database
            onSuccessfulPayment(response.data.customer);
            setLoading(false);
        } else {
            console.error("Error creating customer:", response.data.error);
            setLoading(false);
        }

    };

    return (
        <div className='subscribepage'>
            <h2 className="line-5">Pricing</h2>
            <p className='p-dash-dash'>Our pricing is 
            $0.076 per 1000 tokens (1 token = roughly 4 characters) 
            that you use. That means one website analyzed by the AI may cost a few cents. 
            
            <br/>
            <br/>

            This fee is for us to cover the cost of GPT-4. We intend on adding different AIs for you to choose from that have different cost/capability tradeoffs, but for now GPT-4 is the most effective at web navigation and research. 
            <br/>
            <br/>

            If you don't use our API, your card will never be charged. You can monitor your costs and usage in the Dashboard
             or any time you call the API. You can cancel at any time in the Dashboard.
            </p>
            <form onSubmit={handleSubmit}>
                <CardElement />

                {loading && <p>Loading...</p>}
                {!loading && (
                    <button className='theSpecificButton' type="submit" disabled={!stripe}>
                        Get your API key
                    </button>
                )}


            </form>
            
        </div>
    );
};

export default Payment;
