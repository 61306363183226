import React from 'react';
import "../styles/developers.css";

function Developers() {
    return (
        <div className='devs'>
            <h1 className='d-title'>Developers</h1>



<br/>



    <h2 className='d-title'> What the Webslinger API can and cannot do</h2>


    <p className='line-5'>It can read and analyze websites passively while you focus on other things.</p>
    <p className='line-5'>It can navigate to other sites based on leads that it picks up on during its research process.</p>
    <p className='line-5'>It can return back to you the web content it scraped plus the key insights from the AI's passive research, only if they are important to your objective.</p>
    <p className='line-5'>It can apply this process up to 30 times per second concurrently to automate vast research projects for you.</p>
    <p className='line-5'>It cannot <span className='italicized'>yet</span> find the ideal starting URL to begin its research, you still have to provide that for now.</p>
    <p className='line-5'>It cannot <span className='italicized'>yet</span> interpret visuals like graphs or pictures, but we are adding this capability soon.</p>
    <p className='line-5'>It cannot <span className='italicized'>yet</span> allow switching of the AI model to other models with different cost/capability tradeoffs, but we are adding this capability soon. Currently we use GPT-4.</p>


    <br/>



    <h2 className='d-title'> Example usage:</h2>
    <div className='example-code-container'>
    <div className='example-input'>
        <p className='example-input-code-for-api'>
            <span className="keyword">const</span> <span className="variable">response</span> = <span className="keyword">await</span> <span className="object">axios</span>.<span className="method">post</span>(<span className="string">'https://webslingerv1.ngrok.app/webslinger'</span>,
            {'{'}
            <br/>
            <span className="indent-one"><span className="property">       headers</span>: {'{'}</span>
            <span className="indent-two"><span className="property">'x-api-key'</span>: <span className="string">'YOUR_API_KEY'</span></span>
            <span className="indent-one">{'}'},</span>
            <br/>
            <span className="indent-one"><span className="property">       data</span>: {'{'}</span>
            <br/>
            <span className="indent-two"><span className="property">              maxResearchDepth</span>: max_number_of_urls_to_visit,</span>
            <br/>
            <span className="indent-two"><span className="property">              yourResearchObjective</span>: <span className="string">'What stock will double soon.'</span>,</span>
            <br/>
            <span className="indent-two"><span className="property">              url</span>: url_to_start_on</span>
            <span className="indent-one">{' }'}</span>
            {'}'});
        </p>
    </div>
</div>


<br/>


    <h2 className='d-title'> The API takes in 3 input variables:</h2>

    <p className='line-5'><span className='var'>maxResearchDepth:</span> the maximum number of websites you want the AI to analyze.</p>


    <p className='line-5'><span className='var'>yourResearchObjective:</span> usually a non-googleable question you want an answer to such as "what does the market not understand well with respect to LLM technology and its impact?". This may also be a directive like "find me a highly informed debate about the downsides of ozempic"</p>


    <p className='line-5'><span className='var'>url:</span> the URL you want the AI to begin its navigation and research process on.</p>






    <br/>
    <h2 className='d-title'> The API's output variables, and what they mean:</h2>

    <p className='line-5'><span className='var'>airesponse: </span> the AI's response to the text it read. It will spit back buttons, and text here that it wants to click on to navigate further, only if it finds a compelling lead to pursue.</p>
    <p className='line-5'><span className='var'>accumulatedknowledge:</span> the AI's key insights from these URLs about your research. If there isn't anything relevant, the AI will leave this field empty.</p>
    <p className='line-5'><span className='var'>innertextis:</span> the text the AI read on that webpage</p>
    <p className='line-5'><span className='var'>max research depth:</span> the maximum number of websites you want the AI to research.</p>
    <p className='line-5'><span className='var'>pages:</span> the number of websites the AI visited</p>
    <p className='line-5'><span className='var'>tokens:</span> the number of tokens the AI read and wrote (1 token = roughly 4 characters of text)</p>
    <p className='line-5'><span className='var'>cost:</span> the cost of this API call in USD</p>
    <p className='line-5'><span className='var'>data:</span> an array of objects containing the page number, the text the AI read, the AI's response, and the AI's accumulated knowledge</p>
    <p className='line-5'><span className='var'>page_number:</span> indicates the specific website the AI is currently viewing or referencing during its research process</p>
       <br/>
       <br/>

       <p className='footer-text'>Questions? <a href='mailto:andy@webslingerapi.com'>Email our lead developer.</a></p>

       <br/>
       <br/>


        </div>
        
    );
}

export default Developers;
