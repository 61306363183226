/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      email
      location
      origin
      apikey
      cumulativeowed
      cumulativepaid
      cumulativefreeusagedollars
      maxmonthly
      password
      cumulativetokens
      cumulativetokenspaid
      cumulativefreeusagetokens
      isusersubscribed
      stripetoken
      createdAt
      updatedAt
      subscriptionid
      paymentsuccess
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        location
        origin
        apikey
        cumulativeowed
        cumulativepaid
        cumulativefreeusagedollars
        maxmonthly
        password
        cumulativetokens
        cumulativetokenspaid
        cumulativefreeusagetokens
        isusersubscribed
        stripetoken
        createdAt
        updatedAt
        subscriptionid
        paymentsuccess
      }
      nextToken
    }
  }
`;
export const getVisitor = /* GraphQL */ `
  query GetVisitor($id: ID!) {
    getVisitor(id: $id) {
      id
      action
      location
      origin
      createdAt
      updatedAt
    }
  }
`;
export const listVisitors = /* GraphQL */ `
  query ListVisitors(
    $filter: ModelVisitorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVisitors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        action
        location
        origin
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const usersByApikeyAndId = /* GraphQL */ `
  query UsersByApikeyAndId(
    $apikey: String!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByApikeyAndId(
      apikey: $apikey
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        location
        origin
        apikey
        cumulativeowed
        cumulativepaid
        cumulativefreeusagedollars
        maxmonthly
        password
        cumulativetokens
        cumulativetokenspaid
        cumulativefreeusagetokens
        isusersubscribed
        stripetoken
        createdAt
        updatedAt
        subscriptionid
        paymentsuccess
      }
      nextToken
    }
  }
`;
