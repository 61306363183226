import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LandingPage from './components/landing_page';
import Dashboard from './components/dashboard';
import Signup from './components/signup';
import Navbar from './components/navbar';
import Pricing from './components/pricing';
import Developers from './components/developers';

import './App.css';

const MainContent = () => {
    return (
        <div className='App'>
            <Navbar />
            <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/pricing" element={<Pricing />} />
                <Route path="/docs" element={<Developers />} />

            </Routes>
        </div>
    );
};

const App = () => {
    return (
        <BrowserRouter>
            <MainContent />
        </BrowserRouter>
    );
}

export default App;
